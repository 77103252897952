import Carousal from './Carousal';
import React from 'react'
import Context from './Context';
import Footer from './Footer';
import Enttreat from './Enttreat';
import Dental from './Dental';

const Home = () => {
  return (
    <>
      <div className='mains'>

        <Carousal />
        <Context />
        <Enttreat/>
        <Dental/>
        <Footer />


      </div>


    </>
  )
}

export default Home