import React from 'react'
import './Apps.css';

import Dental2 from './Img/dental icon.jpg';


const Dental = () => {
    return (
        <>
            <br /><br />
            <center>

                <h1> <img src={Dental2}/>Dental  Treatment </h1>
            </center>

            <div className='ent-treat2'>

                <div className='ent22'>
                    <h1>  Removal of wisdom teeth</h1>
                    <p>The removal of wisdom teeth, also known as third molars, is a common dental procedure. Wisdom teeth typically emerge in late adolescence or early adulthood, but they often don't have enough space to grow properly, leading to various dental issues. Removal may be recommended if:</p>
                </div>

                <div className='ent22'>
                    <h1>     Straightening crooked teeth.</h1>
                    <p>Straightening crooked teeth is a common goal for many people seeking to improve their dental alignment and overall oral health. There are several methods available for straightening teeth, and the choice of treatment depends on the severity of the misalignment, the patient's age, and personal preferences. Here are some common methods for straightening crooked teeth: </p>
                </div>

                <div className='ent22'>
                    <h1>Cleaning teeth.</h1>
                    <p>Cleaning your teeth is a crucial part of maintaining good oral health. Regular oral hygiene practices can help prevent issues such as cavities, gum disease, and bad breath. Here's a guide on how to clean your teeth effectively:</p>
                </div>

            </div>


            <div className='ent-treat2'>

                <div className='ent22'>
                    <h1> Cleaning teeth.
                        Crown Bridge.</h1>
                    <p>Toothbrush: Use a soft-bristled toothbrush or an electric toothbrush. Avoid hard-bristled brushes, as they may damage the crown or bridge and irritate the gums.
                        Toothpaste: Use a fluoride toothpaste. Gently brush all surfaces of your teeth, including the crowns and bridges.
                        Technique: Pay special attention to the gumline around the crowns and bridges. Brush at a 45-degree angle to effectively clean the area where the restoration meets the natural tooth.</p>
                </div>

                <div className='ent22'>
                    <h1> Getting false teeth fitted.</h1>
                    <p>Consultation and Examination:

                        Schedule an appointment with your dentist for a consultation. During this visit, the dentist will examine your oral health, discuss your dental history, and assess whether dentures are the appropriate solution for you.
                        Treatment Plan:

                        If dentures are recommended, your dentist will create a treatment plan tailored to your specific needs. This plan may involve extracting any remaining natural teeth, if necessary.</p>
                </div>

                <div className='ent22'>
                    <h1>  Masala filling</h1>
                    <p>It's important to maintain good oral hygiene practices to prevent food particles from getting stuck in your teeth. Regular brushing, flossing, and dental check-ups are essential for keeping your teeth and gums healthy.

                        If the issue persists or if you are experiencing any pain or discomfort, don't hesitate to seek professional dental advice. Your dentist can provide personalized guidance and address any concerns you may have about your dental health.</p>
                </div>

            </div>



            <div className='ent-treat2'>

                <div className='ent22'>
                    <h1>Treatment of broken jaw</h1>
                    <p>A broken jaw, also known as a mandibular fracture, is a serious injury that requires prompt medical attention. The treatment of a broken jaw may involve various approaches depending on the severity and location of the fracture. Here are general steps involved in the treatment of a broken jaw:</p>
                </div>

                <div className='ent22'>
                    <h1>    RCT.</h1>
                    <p>Root canal treatment, also known as endodontic therapy, is a dental procedure used to treat infections or damage to the pulp of a tooth. The pulp is the innermost part of the tooth that contains nerves, blood vessels, and connective tissue. When the pulp becomes infected or inflamed, a root canal is performed to remove the damaged tissue, clean and disinfect the tooth, and seal it to prevent further infection.</p>
                </div>

                <div className='ent22'>
                    <h1>    Braces</h1>
                    <p>Braces are orthodontic devices used to correct misaligned teeth and bite issues. They work by applying continuous pressure to the teeth, gradually moving them into their proper positions. Braces are commonly used to address problems such as crowded teeth, gaps between teeth, overbites, underbites, and crossbites. Here is an overview of the key aspects of braces:</p>
                </div>

            </div>




        </>
    )
}

export default Dental