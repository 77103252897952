import React, { useState } from 'react';
import Footer from '../Components/Footer'




const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    Address: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <>

<br/>

   <center><h1>Contact Us </h1></center>

      <div className='contact'>

        <div className='contact2'>
          <form onSubmit={handleSubmit}>
            <label htmlFor='name'>Name:</label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
            />

            <label htmlFor='email'>Email:</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
            />

            <label htmlFor='Address'>Address:</label>
            <input
              type='Address'
              id='Address'
              name='Address'
              value={formData.Address}
              onChange={handleChange}
            />

            <label htmlFor='message'>Message:</label>
            <textarea
              id='message'
              name='message'
              value={formData.message}
              onChange={handleChange}
            ></textarea>

            <button type='submit'>Submit</button>
          </form>
        </div>

        <div className='contact2'>

          <br/><br/>

        <p><i class="fa fa-phone"></i> 7839303052</p>
                    <p><i class="fa fa-envelope"></i> Upadhyayneha16@gmail.com</p>
                    <p><i class="fa fa-map-marker"></i> Satyapremi Plaza Near balaji Bachapan School Satapreme Nagar Barabanki  225001</p>


        </div>

      </div>


      <Footer />
    </>
  )
}

export default Contact