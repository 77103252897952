import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const CustomNavbar = () => {
  return (
    <BootstrapNavbar expand="lg" className="bg-body-tertiary">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/">Vaibhavi E.N.T. <br /> Dental Clinic </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to='/about'>About</Nav.Link>
            <NavDropdown title="E.N.T. Facilities" id="ent-dropdown">
              <NavDropdown.Item as={Link} to="/ent/microscopic-ear-surgery">Microscopic Ear Surgery</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/fess-septoplasty-mls">Fess Septoplasty & MLS</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/ent-cancer-treatment">E.N.T Cancer Treatment</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/microscopic-ear-surgery">Nasal Laryngeal Endoscopy</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/fess-septoplasty-mls">Endoscopy DCR</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/ent-cancer-treatment">Thyroid Surgery</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/microscopic-ear-surgery">Parotid Surgery</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/fess-septoplasty-mls">Audiometry & Hearing</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ent/ent-cancer-treatment">Diagnosis and treatment of snoring</NavDropdown.Item>

            </NavDropdown>

            <NavDropdown title="Dental Facilities" id="dental-dropdown">
              <NavDropdown.Item as={Link} to="/dental/removal-of-wisdom-teeth">Removal of wisdom teeth</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/straightening-crooked-teeth">Straightening crooked teeth.</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/rct">Cleaning teeth.
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/removal-of-wisdom-teeth">Cleaning teeth. Crown Bridge.</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/straightening-crooked-teeth">Getting false teeth fitted.</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/rct">Masala filling</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/removal-of-wisdom-teeth">Treatment of broken jaw</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/straightening-crooked-teeth">Braces</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dental/rct">RCT</NavDropdown.Item>

            </NavDropdown>

            <Nav.Link as={Link} to='/gallery'>Gallery</Nav.Link>
            <Nav.Link as={Link} to='/contact'>Contact</Nav.Link>
            <Nav.Link as={Link} to='/appointment'>Appointment</Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
}

export default CustomNavbar;
