import React from 'react'

import poster from './Img/poster.png';
import Dental from './Img/dental poster.jpg';


const Context = () => {
    return (


        <div className='main-context'>

            <div className='context'>
                

                <div className='context2'>
                    <img src={poster} />
                </div>

                <div className='context3'>
                    <h1>Your Beautiful Smile Begins With A <br /> E.N.T.  Specialist.</h1>
                    <h2>Dr. Rakesh   kumar Mishra </h2>
                    <h3>MBBS M.S. E.N.T. & CANCER SPECIALIST <br /> Former Doctor District Hospital Barabanki</h3>


                    <p>  Your oral health is intricately connected to your nasal health. Issues like nasal congestion, sinusitis, or allergies can affect your breathing, leading to mouth breathing and potential dental problems. </p>

                    <p>Snoring and sleep apnea, often linked to ENT issues, can impact your sleep quality. Disturbed sleep not only affects your mood and productivity but can also contribute to dental issues like teeth grinding.</p>


                    <p>Sore throats, chronic coughs, or voice changes may signal underlying ENT issues. Ignoring these symptoms could lead to complications and may indirectly affect your oral health.
                    </p>

                    <a href=''> <button> Read More</button></a>

                </div>

            </div>





            <div className='context'>

                <div className='context2'>
                    <img src={Dental} />
                </div>

                <div className='context3'>
                    <h1>"Unlock Your Radiant Smile with Expert Dental Care <br />Your Journey Starts with a Skilled Dentist."</h1>
                    <h2>Dr. Neha    Upadhyay  </h2>
                    <h3>B.D.S  M.D.S (K.G.M.C.)  Dental  Specialist <br /> Professor Prasad Medical College Lucknow </h3>


                    <p>  Beyond your teeth, your oral health is intricately connected to your ability to breathe properly. Conditions like nasal congestion, sinusitis, or allergies can affect your breathing, leading to mouth breathing, which, if left unaddressed, may contribute to potential dental problems.</p>

                    <p>Your sleep quality plays a significant role in dental health. Issues such as snoring and sleep apnea, often associated with dental concerns, can disturb your sleep. Poor sleep not only affects your mood and productivity but may also contribute to dental issues like teeth grinding.</p>


                    <p>Oral health extends to the throat. Sore throats, chronic coughs, or changes in voice might be signals of underlying dental issues. Ignoring these symptoms could lead to complications that indirectly impact your oral health.
                    </p>

                 
                </div>

            </div>



        </div>
    )
}

export default Context