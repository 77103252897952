import React, { useState } from 'react';
import Footer from '../Components/Footer';


const Appointment = () => {
  // State to manage form fields
  const [patientName, setPatientName] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');

  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // You can add your logic here to handle the form data, e.g., make an API call to schedule the appointment.

    // For now, let's log the form data to the console.
    console.log('Patient Name:', patientName);
    console.log('Appointment Date:', appointmentDate);
    console.log('Appointment Time:', appointmentTime);

    // You can reset the form fields after submission if needed.
    setPatientName('');
    setAppointmentDate('');
    setAppointmentTime('');
  };

  return (
   <>
   
   <div className='contain'>
      <h2>Make Doctor Appointment</h2>
      <form onSubmit={handleFormSubmit}>
        <label>
          Patient Name:
          <input
            type="text"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Appointment Date:
          <input
            type="date"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
          />
        </label>
        <br />
        <label>
          Appointment Time:
          <input
            type="time"
            value={appointmentTime}
            onChange={(e) => setAppointmentTime(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>

    <Footer/>
   
   </>
  );
};

export default Appointment;
