import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import Appointment from './Pages/Appointment';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <Router basename=''>
      <>
        <Navbar />

        <Routes>
          {/* Use the '*' wildcard route to always render the Home component */}
          <Route path="*" element={<Home />} />

          {/* Define other routes */}
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Appointment" element={<Appointment />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
