import React from 'react'
import Context from '../Components/Context'
import Footer from '../Components/Footer'

const About = () => {
  return (
   <>
   <br/>

   <center><h1>About Us </h1></center>
   <Context/>
   <Footer/>
   </>
  )
}

export default About