import React from 'react'

import Footer from '../Components/Footer';

import gal1 from '../Components/Img/dental-banner1.jpg';
import gal2 from '../Components/Img/dental-banner2.jpg';



const Gallery = () => {
  return (
    <>

    <br/>
    <center><h1>Gallery </h1></center>
    <br/>
    
    <div>
      <img src={gal1}  style={{width:'100%'}}/>
      <img src={gal2} style={{width:'100%'}} />
    </div>


    <Footer/>
    </>
  )
}

export default Gallery