import React, { useState, useEffect } from 'react';
import Banner1 from './Img/Banner1.jpg';
import Banner2 from './Img/banner2.jpg';
import Banner3 from './Img/banner3.jpg';

import banner4 from './Img/ent banner.webp';
import banner5 from './Img/ent banner2.jpg';

const images = [Banner1, Banner2, Banner3, banner4, banner5];

const Carousal = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    // Automatically change slide every 3 seconds (adjust the interval as needed)
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className='carousal'>
      <img src={images[currentIndex]} alt={`Banner ${currentIndex + 1}`} />
    </div>
  );
};

export default Carousal;
