import React from 'react'
import Backs from './Img/backs.jpg';

const Footer = () => {

    const footerStyle = {
        backgroundImage: `url(${Backs})`,
        backgroundSize: 'cover',  // You can adjust this based on your needs
        // Add other background properties as needed
    };

    return (

        <>


            <div className='footer' style={footerStyle}>

                <div className='foot'>
                    <h1>Vaibhavi E.N.T.
                        Dental Clinic</h1>

                    <p>At Vaibhavi E.N.T. Dental Clinic, we are dedicated to providing comprehensive and compassionate care for all your Ear, Nose, Throat (E.N.T.) and Dental needs. Our state-of-the-art facility is designed to offer a comfortable and welcoming environment for our patients.</p>
                </div>


                <div className='foot'>
                    <h1>Nav Links </h1>

                    <p><a href=''>About</a></p>
                    <p><a href=''>Contact</a></p>
                    <p><a href=''>Gallery</a></p>
                    <p><a href=''>Appointment</a></p>

                </div>


                <div className='foot'>
                    <h1>Useful Links</h1>
                    <p><a href=''>Dental Treatment</a></p>
                    <p><a href=''>E.N.T. Treatment</a></p>

                    <h1>Clinic Doctor's</h1>
                    <p><a href=''>Dr. Neha Upadhyay</a></p>
                    <p><a href=''>Dr. Rakesh kumar Mishra</a></p>
                   
                </div>


                <div className='foot'>
                    <h1>Reach Us </h1>
                    <p><i class="fa fa-phone"></i> 7839303052</p>
                    <p><i class="fa fa-envelope"></i> Upadhyayneha16@gmail.com</p>
                    <p><i class="fa fa-map-marker"></i> Satyapremi Plaza Near balaji Bachapan School Satapreme Nagar Barabanki  225001</p>
                </div>



            </div>



            <div className='disclamer'>


            <p> © 2024  Vaibhavi E.N.T.  Dental Clinic  All rights reserved.   Developed By  Creative Digital Word </p>

            </div>

        </>
    )
}



export default Footer