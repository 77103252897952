import React from 'react'
import './Apps.css';
import Ent from './Img/ent.jpg'

const Enttreat = () => {
    return (
        <>
            <br /><br />
            <center>

                <h1>  <img src={Ent}/></h1>

                <br/>
                <h1>TREATMENT</h1>

                <br/>
               
            </center>

            <div className='ent-treat'>

                <div className='ent2'>
                    <h1>Microscopic Ear Surgery</h1>
                    <p>Microscopic ear surgery, also known as otologic surgery or ear microsurgery, is a specialized field within the broader discipline of otolaryngology (ear, nose, and throat medicine). It involves the use of a surgical microscope and other advanced tools to perform intricate procedures on the structures of the ear. This type of surgery is typically used to treat various ear conditions, including hearing loss, chronic infections, tumors, and congenital abnormalities. Here is some content covering different aspects of microscopic ear surgery:</p>
                </div>

                <div className='ent2'>
                    <h1> Fess Septoplasty & MLS</h1>
                    <p>Overview: FESS is a minimally invasive surgical procedure performed to treat chronic sinusitis and other sinus disorders. It involves the use of an endoscope, a thin tube with a light and camera, to visualize and remove blockages in the sinuses.
                        Indications: FESS is commonly used for conditions such as chronic sinusitis, nasal polyps, and sinus tumors.
                        Procedure: Surgeons use small instruments and the endoscope to access the sinuses through the nostrils, </p>
                </div>

                <div className='ent2'>
                    <h1>E.N.T Cancer Treatment</h1>
                    <p>ENT (Ear, Nose, and Throat) cancer, also known as head and neck cancer, can affect various areas in the head and neck region, including the oral cavity, throat, larynx, nasal cavity, and salivary glands. The treatment of ENT cancer typically involves a multidisciplinary approach, with input from surgeons, radiation oncologists, medical oncologists, and other healthcare professionals. The specific treatment plan depends on factors such as the type of cancer, its stage, and the patient's overall health. Here's an overview of common treatment modalities for ENT cancer:</p>
                </div>

            </div>


            <div className='ent-treat'>

                <div className='ent2'>
                    <h1>  Nasal Laryngeal  Endoscopy</h1>
                    <p>Nasal laryngeal endoscopy, also known as nasolaryngoscopy or flexible laryngoscopy, is a medical procedure used to examine the nasal passages, throat (pharynx), and larynx (voice box) using a flexible, thin tube with a light and camera called an endoscope. This diagnostic procedure is commonly performed by ear, nose, and throat (ENT) specialists to assess various conditions affecting the upper airway. Here's an overview of nasal laryngeal endoscopy:</p>
                </div>

                <div className='ent2'>
                    <h1>  Endoscopy DCR</h1>
                    <p>Endoscopy DCR, or Endoscopic Dacryocystorhinostomy, is a surgical procedure used to treat a blocked tear duct (nasolacrimal duct) by creating a new drainage pathway between the lacrimal sac (where tears collect) and the nasal cavity. This procedure is performed using an endoscope, a thin, flexible tube with a light and camera, to visualize and navigate through the nasal passages and lacrimal system. DCR is typically indicated when the tear duct is obstructed, leading to excessive tearing, recurrent infections, or the formation of a persistent fluid-filled sac (dacryocystocele). </p>
                </div>

                <div className='ent2'>
                    <h1>  Thyroid Surgery</h1>
                    <p>Thyroid surgery, also known as thyroidectomy, involves the removal of all or part of the thyroid gland. The thyroid is a butterfly-shaped gland located in the neck, just below the Adam's apple. Thyroid surgery is performed for various reasons, including the treatment of thyroid cancer, removal of benign nodules or goiters, and management of hyperthyroidism (overactive thyroid). There are different types of thyroid surgery, each with its own indications and outcomes. Here's an overview:</p>
                </div>

            </div>



            <div className='ent-treat'>

                <div className='ent2'>
                    <h1>    Parotid Surgery</h1>
                    <p>Parotid surgery involves the removal of all or part of the parotid gland, which is the largest of the salivary glands located near the ear. The parotid gland produces saliva, and surgery may be necessary for various reasons, including the removal of tumors, treatment of infections, or management of chronic conditions affecting the gland. Here's an overview of parotid surgery:</p>
                </div>

                <div className='ent2'>
                    <h1>     Audiometry & Hearing</h1>
                    <p>Audiometry is a diagnostic test that measures an individual's ability to hear various sounds, especially pure tones at different frequencies. The test is commonly performed by audiologists to assess the hearing function and identify any hearing loss. Here's an overview of audiometry and hearing-related concepts:</p>
                </div>

                <div className='ent2'>
                    <h1>    Diagnosis and treatment of snoring</h1>
                    <p>Snoring is a common condition that occurs when the flow of air through the mouth and nose is partially blocked during sleep, leading to the vibration of tissues in the throat. While occasional snoring is often considered normal, persistent and loud snoring can be a sign of an underlying issue. Here's an overview of the diagnosis and treatment of snoring:</p>
                </div>

            </div>




        </>
    )
}

export default Enttreat